(function ($) {
    function wpLoginInit() {
        if($('body').hasClass('login')) {
            $('input[type="text"]').attr('placeholder', 'Username or Email');
            $('input[type="password"]').attr('placeholder', 'Password');
            $(".forgetmenot").append('<a href="#" class="checkbox-button" value="0">Stay Logged In</a>');
            $(".checkbox-button").click(function () {
                if ($('#rememberme').attr('checked') != 'checked') {
                    $('#rememberme').attr('checked', 'checked');
                    $(this).addClass('checkbox-button--checked');
                } else {
                    $('#rememberme').removeAttr('checked');
                    $(this).removeClass('checkbox-button--checked');
                }
            });
        }
    }

    $(document).on('ready', wpLoginInit);
})(jQuery);