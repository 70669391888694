/* global app */
app.backToTop = (function ($) {
    var backToTop = {

        el: '.js-back-to-top',

        init: function () {
            backToTop.el = $(backToTop.el);

            backToTop.el.on('click', backToTop.doScroll);
            $(window).scroll(backToTop.buttonHandler);
        },

        buttonHandler: function () {
            $(window).scrollTop() > 300 ? backToTop.el.addClass('is-visible') : backToTop.el.removeClass('is-visible');
        },

        doScroll: function (e) {
            e.preventDefault();

            $('body, html').animate({
                    scrollTop: 0,
                }, 700
            );
        }
    };

    $(document).on('ready', backToTop.init);

    return backToTop;

})(jQuery);