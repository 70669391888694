(function ($) {
    var videos;

    function initVideos() {
        videos = $('iframe[src*="vimeo.com"], iframe[src*="youtube.com"]');

        videos.each(function () {
            var el = $(this);
            el.wrap('<div class="responsive-embed widescreen"/>');
        });
    }

    $(document).on('ready', initVideos);

    return {};

})(jQuery);