/*global app*/
app.stickyFooter = (function () {
    var stickyFooter = {
        init: function() {
            var footer = $('.js-sticky-footer'),
                pos = footer.position(),
                height = $(window).height();

            height = height - pos.top;
            height = height - footer.height() - 1;
            height = height - app.utility.adminbarOffset() - 32;

            if (height > 0) {
                footer.css({
                    'margin-top': height + 'px'
                });
            }
        }
    };

    $(document).on('ready', stickyFooter.init);

    return stickyFooter;

})(jQuery);