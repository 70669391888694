/* global app */
app.utility = (function ($) {
    var utility = {
        adminbarOffset: function () {
            var body = $('body'),
                adminbar = $('#wpadminbar');

            return body.hasClass('admin-bar') && adminbar.length ? adminbar.outerHeight() : '0';
        },
    };

    return utility;

})(jQuery);